





























import { defineComponent, ref, watch } from "@vue/composition-api";
import { useDropZone, useFileDialog, useObjectUrl } from "@vueuse/core";
import { NOTIFICATION_STATUS } from "@monorepo/utils/src/eventBus/types/notification";
import { showNotification } from "@monorepo/utils/src/eventBus/utils/showNotification";

export default defineComponent({
  name: "AvatarDialog",
  emits: ["close"],
  setup(props, { root, emit }) {
    const allowedFileTypes = ["image/jpg", "image/jpeg", "image/png", "image/gif"];
    const maxFileSize = 5 * 1024 * 1024; // 5mb

    const dropZoneRef = ref();
    const selectedFile = ref<File | null>(null);
    const isFileSizeError = ref(false);
    const isLoading = ref(false);

    const selectedFileUrl = useObjectUrl(selectedFile);
    const { open, onChange } = useFileDialog({
      multiple: false,
      accept: allowedFileTypes.join(", "),
      reset: true,
    });
    const { isOverDropZone: isDocumentBodyOverDropZone } = useDropZone(document.body);
    useDropZone(dropZoneRef, {
      onDrop: (files) => {
        const file = files?.[0];

        if (file && allowedFileTypes.includes(file.type)) {
          handleFileSize(file);
        }
      },
    });

    const handleFileSize = (file: File) => {
      isFileSizeError.value = false;

      if (file.size <= maxFileSize) {
        selectedFile.value = file;
      } else {
        isFileSizeError.value = true;
      }
    };

    const save = async () => {
      if (!selectedFile.value) return;

      try {
        isLoading.value = true;

        const formData = new FormData();
        formData.append("file", selectedFile.value);

        await root.$store.dispatch("auth/saveUserAvatar", formData);

        URL.revokeObjectURL(root.$store.state.auth.userAvatar);
        root.$store.commit("auth/setUserAvatar", URL.createObjectURL(selectedFile.value));

        close();
        showNotification("Фото профиля успешно сохранено.", NOTIFICATION_STATUS.SUCCESS);
      } catch (e) {
        console.error(e);
        showNotification("Ошибка сохранения фото профиля.");
      } finally {
        isLoading.value = false;
      }
    };

    const reset = () => {
      selectedFile.value = null;
    };

    const close = () => {
      emit("close");
    };

    onChange((files) => {
      if (files?.[0]) handleFileSize(files[0]);
    });

    watch(isDocumentBodyOverDropZone, (nv) => {
      /** Сделана проверка чтобы при завершении перетаскивания не очищалась ошибка */
      if (nv) isFileSizeError.value = false;
    });

    return {
      isLoading,
      selectedFileUrl,
      dropZoneRef,
      isFileSizeError,
      open,
      save,
      reset,
      close,
    };
  },
});
