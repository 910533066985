




























import { defineComponent, ref } from "@vue/composition-api";
import { v4 as uuid } from "uuid";
import { mapActions, mapGetters } from "vuex";
import { NOTIFICATION_STATUS } from "@monorepo/utils/src/eventBus/types/notification";
import { showNotification } from "@monorepo/utils/src/eventBus/utils/showNotification";
import useCheckChangesModal from "@monorepo/utils/src/composables/useCheckChangesModal";
import InfoBlock from "@monorepo/app/src/views/Profile/components/info/InfoBlock.vue";
import { infoElements } from "@monorepo/utils/src/variables/projectsData/profileView/infoElements";
import { convertUserFromApi } from "@monorepo/app/src/views/Profile/components/info/utils/convertUserFromApi";
import { viewUniqKey } from "@monorepo/utils/src/variables/projectsData/profileView/viewTitle";
import { convertSaveCardObject } from "@monorepo/app/src/views/Profile/components/info/utils/convertSaveCardObject";
import StructureSettings from "@monorepo/app/src/views/Profile/components/info/StructureSettings.vue";
import { environmentVariables } from "@monorepo/utils/src/variables/environmentVariables";
import AvatarDialog from "./AvatarDialog.vue";

export default defineComponent({
  name: "AvatarSettings",
  components: {
    InfoBlock,
    StructureSettings,
    AvatarDialog,
  },
  data(): {
    key: string;
    viewUniqKey: string;
    isSaveLoading: boolean;
    isLoading: boolean;
    isAvatarDialog: boolean;
    infoElements: typeof infoElements;
  } {
    return {
      isSaveLoading: false,
      key: uuid(),
      viewUniqKey,
      isLoading: false,
      isAvatarDialog: false,
      infoElements,
    };
  },
  computed: {
    ...mapGetters("auth", ["user", "isShowAnimation", "userAvatar"]),
    rolesList() {
      return (this.user as any)?.roles || [];
    },
    profileEmpty(): string {
      return `${environmentVariables.BASE_URL}assets/images/common/profile.svg`;
    },
    imageSrc(): string {
      return this.userAvatar || this.profileEmpty;
    },
    fullName(): string {
      return this.user?.details?.fullName || "";
    },
  },
  methods: {
    ...mapActions(["logout"]),
    ...mapActions("auth", ["saveUserInfo"]),
    resetPassword() {
      this.$router.push("/reset-password");
    },
    async logoutCb() {
      await this.logout();
      await this.$router.push("/login");
    },
    getResultSaveData(data: any) {
      return convertSaveCardObject(data);
    },
    async onSave(data: Record<string, string | boolean>) {
      const isNeedSave = (this as unknown as { checkChangesBeforeSave: () => boolean }).checkChangesBeforeSave();
      if (isNeedSave) {
        const resultData = this.getResultSaveData(data);

        this.isSaveLoading = true;
        const isSaved = await this.saveUserInfo(resultData).finally(() => {
          this.isSaveLoading = false;
        });
        if (isSaved) {
          this.changedFormValues = resultData;
          showNotification("Данные успешно сохранены.", NOTIFICATION_STATUS.SUCCESS);
        }
      } else {
        return;
      }
    },
    changeContracts(contracts: Record<string, string>[], values: Record<string, string>) {
      (
        this as unknown as {
          $formulate: {
            setValues: (key: string, object: Record<string, string>) => void;
          };
        }
      ).$formulate.setValues("user-info", Object.assign(values, { contracts }));
    },
  },
  mounted() {
    this.formValues = convertUserFromApi(this.user);
    this.changedFormValues = this.getResultSaveData(this.formValues);
  },
  setup(props, context) {
    const { root } = context;
    const formValues = ref({});
    const changedFormValues = ref({});
    const { checkChangesBeforeClose, checkChangesBeforeSave } = useCheckChangesModal(context, formValues, changedFormValues, convertSaveCardObject);

    return {
      formValues,
      changedFormValues,
      checkChangesBeforeClose,
      checkChangesBeforeSave,
    };
  },
});
