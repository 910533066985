export const convertUserFromApi = (user?: any, userInfo?: any) => {
  return {
    login: user?.name || "-",
    email: user?.details?.email || "-",
    department: user?.details?.department || "-",
    position: user?.details?.position || "-",
    phone: user?.details?.phone || "-",
    messenger: userInfo?.messenger || "",
  };
};
