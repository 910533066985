


















































import { defineComponent, PropType } from "@vue/composition-api";
import CardModalInfoElement from "@monorepo/uikit/src/components/tableCardModal/CardModalInfoElement.vue";
import FormInputElement from "@monorepo/uikit/src/components/tableCardModal/FormInputElement.vue";
import { IModalElementType } from "@monorepo/utils/src/types/modalElementType";
import mask from "@monorepo/utils/src/directives/mask";
import CheckboxElement from "@monorepo/uikit/src/components/Settings/CheckboxElement.vue";

export default defineComponent({
  name: "CardModalChapter",
  components: {
    CardModalInfoElement,
    FormInputElement,
    CheckboxElement,
  },
  directives: {
    mask,
  },
  data() {
    return {};
  },
  props: {
    title: {
      type: String,
      default: "",
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
    modalElements: {
      type: Array as PropType<IModalElementType[]>,
      default: () => [],
    },
    formValues: {
      type: Object,
      default: () => ({}),
    },
  },
  methods: {
    clickElementCb(element: IModalElementType) {
      if (!element.isLink) {
        return;
      }
      this.$emit("clickElement", element);
    },
    changeElementCb(element: IModalElementType) {
      this.$emit("changeElement", element);
    },
  },
});
