import { v4 as uuid } from "uuid";

export const infoElements = [
  {
    title: "Основные данные",
    id: uuid(),
    children: [
      {
        id: uuid(),
        isEdited: false,
        title: "Логин",
        tooltip: "Логин",
        className: "info-modal__element_title-info",
        value: "login",
      },
      {
        id: uuid(),
        isEdited: false,
        title: "Электронная почта",
        tooltip: "Электронная почта",
        className: "info-modal__element_title-info",
        value: "email",
      },
      {
        id: uuid(),
        isEdited: false,
        title: "Подразделение",
        tooltip: "Подразделение",
        className: "info-modal__element_title-info",
        value: "department",
        isShowContentTooltip: true,
      },
    ],
  },
  {
    title: "Дополнительные данные",
    id: uuid(),
    children: [
      {
        id: uuid(),
        isEdited: true,
        title: "Телефон",
        tooltip: "Телефон",
        className: "info-modal__element_title-info",
        value: "phone",
      },
      {
        id: uuid(),
        isEdited: true,
        title: "Должность",
        tooltip: "Должность",
        className: "info-modal__element_title-info",
        value: "position",
      },
      {
        id: uuid(),
        isEdited: true,
        title: "Мессенджер",
        tooltip: "Мессенджер",
        className: "info-modal__element_title-info",
        value: "messenger",
      },
    ],
  },
];
